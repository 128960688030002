<template>
  <Auth />
</template>

<script>
import Auth from '@/components/Auth'
export default {
  name: 'SignUp',
  components: { Auth }
}
</script>
